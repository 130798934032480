import React, { useState } from "react";
import { Layout, Menu, message } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  DollarOutlined,
  InboxOutlined,
  CustomerServiceOutlined,
  FileProtectOutlined,
  ShopOutlined,
  SolutionOutlined,
  TransactionOutlined,
  TagOutlined,
  VerifiedOutlined,
  BorderOuterOutlined,
  AppstoreAddOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { MdColorLens, MdFormatListBulleted } from "react-icons/md";
import { FaTags } from "react-icons/fa";
import { GiBookshelf } from "react-icons/gi";
import "../App.css";
import { Link, Route, Switch, useHistory, useLocation } from "react-router-dom";
import Users from "./Users";
import Guides from "./Guides";
import Patches from "./Patches";
import Tags from "./Tags";
import Types from "./Types";
import Border from "./Border";
import Color from "./Color";
import Sets from "./Sets";
import Home from "./Home";
import { useDispatch } from "react-redux";
import { useAuth } from "../contexts/AuthContext";

const { Header, Sider, Content } = Layout;
const LayoutSide = () => {
  const [collapsed, setCollapsed] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const { currentUser, logout } = useAuth();
  async function handleLogout() {
    try {
      await logout();
      history.push("/");
    } catch {
      message.error("Failed to log out");
    }
  }

  const handleClick = (e) => {
    console.log(e.key);
  };
  return (
    <Layout style={{ height: "100vh" }} theme="dark">
      <Sider
        theme="dark"
        width={200}
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          setCollapsed(broken);
        }}
        // onCollapse={(collapsed, type) => {
        //   console.log(collapsed, type);
        // }}
        trigger={null}
        // collapsible
        collapsed={collapsed}
      >
        <div className="logo">
          <img src="/images/logo.png" alt="" />
          {/* <p className="txtLogo"></p> */}
        </div>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={["/dashboard"]}
          selectedKeys={[location.pathname]}
        >
          <Menu.Item key="/dashboard" icon={<HomeOutlined />}>
            <Link to="/dashboard"> Home</Link>
          </Menu.Item>
          <Menu.Item key="/dashboard/users" icon={<UserOutlined />}>
            <Link to="/dashboard/users"> Users</Link>
          </Menu.Item>
          <Menu.Item key="/dashboard/guides" icon={<GiBookshelf />}>
            <Link to="/dashboard/guides"> Guides</Link>
          </Menu.Item>
          <Menu.Item key="/dashboard/sets" icon={<AppstoreAddOutlined />}>
            <Link to="/dashboard/sets"> Sets</Link>
          </Menu.Item>
          <Menu.Item key="/dashboard/tags" icon={<FaTags />}>
            <Link to="/dashboard/tags"> Tags</Link>
          </Menu.Item>
          <Menu.Item key="/dashboard/types" icon={<MdFormatListBulleted />}>
            <Link to="/dashboard/types"> Types</Link>
          </Menu.Item>
          <Menu.Item key="/dashboard/borders" icon={<BorderOuterOutlined />}>
            <Link to="/dashboard/borders"> Borders</Link>
          </Menu.Item>
          <Menu.Item key="/dashboard/colors" icon={<MdColorLens />}>
            <Link to="/dashboard/colors"> Colors</Link>
          </Menu.Item>
        </Menu>
        <div className="version">
          {/* <img src="/images/logo.png" alt="" /> */}
          <p className="txtLogo">Version 1.0.2</p>
        </div>
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={{
            paddingLeft: 20,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: () =>
                collapsed ? setCollapsed(false) : setCollapsed(true),
            }
          )}
          {collapsed ? (
            <div className="logo">
              <img src="/images/logo.png" alt="" />
              {/* <p className="txtLogo"></p> */}
            </div>
          ) : null}
          <h4
            style={{ cursor: "pointer", color: "#fff" }}
            onClick={handleLogout}
          >
            logout
          </h4>
        </Header>
        <Content
          className="site-layout-background"
          style={{
            margin: "24px 16px",
            padding: 24,
            height: "100vh",
            overflowY: "auto",
          }}
        >
          <Switch>
            <Route exact path="/dashboard" component={Home} />
            <Route exact path="/dashboard/users" component={Users} />
            <Route exact path="/dashboard/guides" component={Guides} />
            <Route exact path="/dashboard/patches" component={Patches} />
            <Route exact path="/dashboard/tags" component={Tags} />
            <Route exact path="/dashboard/types" component={Types} />
            <Route exact path="/dashboard/borders" component={Border} />
            <Route exact path="/dashboard/colors" component={Color} />
            <Route exact path="/dashboard/sets" component={Sets} />
          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutSide;
