const prod = {
  api: {
    URL: 'http://68.183.98.199:5012',
  },
  serverUrl: 'http://68.183.98.199:5012',
};

// const config = process.env.REACT_APP_STAGE === 'production' ? prod : dev;

export default {
  ...prod,
};
