import { db } from "../firebase";

const typesRef = db.collection("newTypes");

export const getType = (data) =>
  new Promise(async (resolve, reject) => {
    try {
      let temp = [];
      const snap = await typesRef.orderBy("Abbreviation", "asc").get();
      snap.forEach((doc) => temp.push({ id: doc.id, ...doc.data() }));

      return resolve(temp);
    } catch (error) {
      return reject(error);
    }
  });

export const createType = (data) =>
  new Promise(async (resolve, reject) => {
    try {
      const Type = await typesRef.add(data);

      return resolve("successfully created!");
    } catch (error) {
      return reject(error);
    }
  });

export const updateType = (data) =>
  new Promise(async (resolve, reject) => {
    try {
      const Type = await typesRef.doc(data.id).update(data);

      return resolve("successfully updated!");
    } catch (error) {
      return reject(error);
    }
  });

export const deleteType = (data) =>
  new Promise(async (resolve, reject) => {
    try {
      await typesRef.doc(data).delete();

      return resolve("successfully deleted!");
    } catch (error) {
      return reject(error);
    }
  });
