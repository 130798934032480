import { db } from "../firebase";

const tagsRef = db.collection("newTags");

export const getTag = (data) =>
  new Promise(async (resolve, reject) => {
    try {
      let temp = [];
      const snap = await tagsRef.orderBy("title", "asc").get();
      snap.forEach((doc) => temp.push({ id: doc.id, ...doc.data() }));

      return resolve(temp);
    } catch (error) {
      return reject(error);
    }
  });

export const createTag = (data) =>
  new Promise(async (resolve, reject) => {
    try {
      const Tag = await tagsRef.add(data);

      return resolve("successfully created!");
    } catch (error) {
      return reject(error);
    }
  });

export const updateTag = (data) =>
  new Promise(async (resolve, reject) => {
    try {
      const Tag = await tagsRef.doc(data.id).update(data);

      return resolve("successfully updated!");
    } catch (error) {
      return reject(error);
    }
  });

export const deleteTag = (data) =>
  new Promise(async (resolve, reject) => {
    try {
      await tagsRef.doc(data).delete();

      return resolve("successfully deleted!");
    } catch (error) {
      return reject(error);
    }
  });
