import { db } from "../firebase";

const borderRef = db.collection("newBorder");

export const getBorder = (data) =>
  new Promise(async (resolve, reject) => {
    try {
      let temp = [];
      const snap = await borderRef.orderBy("Abbreviation", "asc").get();
      snap.forEach((doc) => temp.push({ id: doc.id, ...doc.data() }));

      return resolve(temp);
    } catch (error) {
      return reject(error);
    }
  });

export const createBorder = (data) =>
  new Promise(async (resolve, reject) => {
    try {
      const Border = await borderRef.add(data);

      return resolve("successfully created!");
    } catch (error) {
      return reject(error);
    }
  });

export const updateBorder = (data) =>
  new Promise(async (resolve, reject) => {
    try {
      const Border = await borderRef.doc(data.id).update(data);

      return resolve("successfully updated!");
    } catch (error) {
      return reject(error);
    }
  });

export const deleteBorder = (data) =>
  new Promise(async (resolve, reject) => {
    try {
      await borderRef.doc(data).delete();

      return resolve("successfully deleted!");
    } catch (error) {
      return reject(error);
    }
  });
