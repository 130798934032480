import { db, storage } from "../firebase";
import moment from "moment";

const guideRef = db.collection("newGuide");

export const getGuide = (data) =>
  new Promise(async (resolve, reject) => {
    try {
      let temp = [];
      const snap = await guideRef.get();
      snap.forEach((doc) => temp.push({ id: doc.id, ...doc.data() }));
      console.log(temp);
      return resolve(temp);
    } catch (error) {
      return reject(error);
    }
  });

export const createGuide = (data, image) =>
  new Promise(async (resolve, reject) => {
    try {
      const Guide = await guideRef.add({
        ...data,
        published: false,
        datetime: Number(moment(new Date()).format("X")),
        setUrls: [],
      });

      if (image) {
        const resp = await storage.ref(`images/${image.name}`);
        const url = await resp.put(image).then(() => resp.getDownloadURL());
        await guideRef.doc(Guide.id).update({
          image_url: url,
        });
      }

      return resolve("successfully created!");
    } catch (error) {
      return reject(error);
    }
  });

export const updateGuide = (data, image) =>
  new Promise(async (resolve, reject) => {
    try {
      const Guide = await guideRef.doc(data.id).update(data);

      if (image) {
        storage.refFromURL(image).delete();
        const resp = await storage.ref(`images/${image.name}`);
        const url = await resp.put(image).then(() => resp.getDownloadURL());
        await guideRef.doc(data.id).update({
          image_url: url,
        });
      }

      return resolve("successfully updated!");
    } catch (error) {
      return reject(error);
    }
  });

export const deleteGuide = (data) =>
  new Promise(async (resolve, reject) => {
    try {
      await guideRef.doc(data).delete();

      return resolve("successfully deleted!");
    } catch (error) {
      return reject(error);
    }
  });
