import React from 'react'
import { Form, Input, InputNumber, Radio, Modal, Cascader } from 'antd'
const formItemLayout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 14,
    },
  }
const ModalForm = ({isModalVisible, setIsModalVisible, ...props}) => {
     const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

    return (
        <Modal visible={isModalVisible} footer={null} onOk={handleOk} onCancel={handleCancel} >
        {
          props.children
        }
      </Modal>
    )
}

export default ModalForm
