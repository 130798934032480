import React, { useState, useEffect } from "react";
import { Table, Modal, Button, Row, Col, Form, Input, message } from "antd";
import DropOption from "../components/DropOption";
import ModalForm from "../components/ModalForm";
// import { db } from "../firebase";
import {
  getColor,
  createColor,
  updateColor,
  deleteColor,
} from "../utils/colorApi";
// import _ from "lodash";

const { confirm } = Modal;
const { Search } = Input;

const Color = () => {
  const formItemLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const columns = [
    {
      title: "Color Name",
      dataIndex: "name",
      width: "10%",
    },
    {
      title: "Color Description",
      dataIndex: "description",
      width: "10%",
    },
    {
      title: "Hex Code",
      dataIndex: "hex",
      width: "10%",
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: "8%",
      render: (text, record) => {
        return (
          <DropOption
            onMenuClick={(e) => handleMenuClick(record, e)}
            menuOptions={[
              { key: "1", name: `Update` },
              { key: "2", name: `Delete` },
            ]}
          />
        );
      },
    },
  ];

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [form] = Form.useForm();

  const handleMenuClick = (record, e) => {
    if (e.key === "1") {
      setEditModalVisible(true);
      console.log(record);
      form.setFieldsValue({
        id: record.id,
        name: record.name,
        description: record.description,
        hex: record.hex,
      });
    } else if (e.key === "2") {
      confirm({
        title: `Are you sure you want to delete this item?`,
        async onOk() {
          // const newData = data.filter((item, i) => i !== record.id);
          // console.log(newData);
          // db.collection("colors").doc("colors").update({
          //   colors: newData,
          // });
          const res = await deleteColor(record.id);
          message.success(res);
          //   storage.refFromURL(record.image_url).delete();
          fetchColors();
        },
      });
    }
  };

  const fetchColors = async () => {
    try {
      const res = await getColor();
      console.log(res);
      setTableData(res);
      // setTableData(
      //   _.sortBy(newData, function (i) {
      //     return i.name.toLowerCase();
      //   })
      // );
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchColors();
  }, []);

  const handleAddProduct = async (value) => {
    setLoading(true);

    const res = await createColor(value);
    setIsModalVisible(false);
    message.success(res);
    setLoading(false);
    fetchColors();

    // db.collection("colors")
    //   .doc("colors")
    //   .set({
    //     colors: [...data, `${value.name}|${value.description}|${value.hex}`],
    //   })
    //   .then(async (doc) => {
    //     setIsModalVisible(false);
    //     message.success("Color created!");
    //     setLoading(false);
    //     fetchColors();
    //   })
    //   .catch(function (error) {
    //     setIsModalVisible(false);
    //     message.error("Color not created!");
    //     setLoading(false);
    //     console.log(error);
    //   });
  };

  const handleEditProduct = async (value) => {
    setLoading(true);

    const res = await updateColor(value);
    setEditModalVisible(false);
    message.success(res);
    setLoading(false);
    fetchColors();

    // const newData = data.filter((item, i) => i !== value.id);
    // console.log(newData);
    // db.collection("colors")
    //   .doc("colors")
    //   .update({
    //     colors: [...newData, `${value.name}|${value.description}|${value.hex}`],
    //   })
    //   .then(async (doc) => {
    //     setEditModalVisible(false);
    //     message.success("Color updated!");
    //     setLoading(false);
    //     fetchColors();
    //   })
    //   .catch(function (error) {
    //     setEditModalVisible(false);
    //     message.error("Color not updated!");
    //     setLoading(false);
    //     console.log(error);
    //   });
  };

  const handleSearch = (keyword) => {
    console.log(keyword);
    if (keyword.length) {
      const newData = tableData.filter((item) => {
        if (
          item.description.toLowerCase().includes(keyword.toLowerCase()) ||
          item.name.toLowerCase().includes(keyword.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });

      setTableData(newData);
    } else {
      fetchColors();
    }
  };

  return (
    <div className="App">
      <ModalForm
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      >
        <Form name="control-ref" layout="vertical" onFinish={handleAddProduct}>
          <Form.Item
            name="name"
            rules={[{ required: true }]}
            label={`Color Name`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            rules={[{ required: true }]}
            label={`Color Description`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="hex"
            rules={[{ required: true }]}
            label={`Hex Code`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>

          <Row justify="center">
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              style={{ width: "80%" }}
            >
              Submit
            </Button>
          </Row>
        </Form>
      </ModalForm>
      <ModalForm
        isModalVisible={editModalVisible}
        setIsModalVisible={setEditModalVisible}
      >
        <Form
          name="edit-fomr"
          layout="vertical"
          form={form}
          onFinish={handleEditProduct}
        >
          <Form.Item
            name="name"
            rules={[{ required: true }]}
            label={`Color Name`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            rules={[{ required: true }]}
            label={`Color Description`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="hex"
            rules={[{ required: true }]}
            label={`Hex Code`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ display: "none" }}
            name="id"
            rules={[{ required: true }]}
            label={`ID`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>
          <Row justify="center">
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              style={{ width: "80%" }}
            >
              Submit
            </Button>
          </Row>
        </Form>
      </ModalForm>

      <Form name="control-ref">
        <Row justify="space-between">
          <Col span={8}>
            <Form.Item name="name">
              <Search placeholder={`Search Name`} onSearch={handleSearch} />
            </Form.Item>
          </Col>

          <Col span={2}>
            <Button
              type="ghost"
              onClick={() => {
                setIsModalVisible(true);
              }}
            >
              Create
            </Button>
          </Col>
        </Row>
      </Form>
      <div
        style={{
          flexDirection: "row",
          justifyContent: "center",
          marginLeft: 20,
          marginRight: 20,
        }}
      >
        <Table
          // {...tableProps}
          pagination={{ position: ["topRight"] }}
          dataSource={tableData}
          // className={styles.table}
          bordered
          scroll={{ y: 575 }}
          columns={columns}
          simple
        />
      </div>
    </div>
  );
};

export default Color;
