import {createStore, applyMiddleware} from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

import reducer from './reducer';

export default function configureStore() {
  var store = createStore(
    reducer,
    // initialState,
    applyMiddleware(thunk, logger),
  );

  return store;
}
