import React, { useState, useEffect } from "react";
import { Table, Modal, Button, Row, Col, Form, Input, message } from "antd";
import DropOption from "../components/DropOption";
import ModalForm from "../components/ModalForm";
import { v4 as uuidv4 } from "uuid";
import { getTag, createTag, updateTag, deleteTag } from "../utils/tagsApi";
import { db } from "../firebase";
import _ from "lodash";

const { confirm } = Modal;
const { Search } = Input;

const Tags = () => {
  const formItemLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const columns = [
    {
      title: "Tag Title",
      dataIndex: "title",
      key: "title",
      width: "10%",
    },
    {
      title: "Tag Description",
      dataIndex: "description",
      key: "description",
      width: "10%",
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: "8%",
      render: (text, record) => {
        return (
          <DropOption
            onMenuClick={(e) => handleMenuClick(record, e)}
            menuOptions={[
              { key: "1", name: `Update` },
              { key: "2", name: `Delete` },
            ]}
          />
        );
      },
    },
  ];

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [form] = Form.useForm();

  const handleMenuClick = (record, e) => {
    if (e.key === "1") {
      setEditModalVisible(true);
      console.log(record);
      form.setFieldsValue({
        id: record.id,
        title: record.title,
        description: record.description,
      });
    } else if (e.key === "2") {
      confirm({
        title: `Are you sure you want to delete this item?`,
        async onOk() {
          // db.collection("tags").doc(record.id).delete();
          //   storage.refFromURL(record.image_url).delete();
          const res = await deleteTag(record.id);
          message.success(res);
          fetchTags();
          // message.success("Successfully delete!");
        },
      });
    }
  };

  const fetchTags = async () => {
    let newData = [];
    try {
      const res = await getTag();
      console.log(res);
      setData(res);
      // db.collection("tags")
      //   .get()
      //   .then((querySnapshot) => {
      //     querySnapshot.forEach((doc) => {
      //       const itemData = doc.data();
      //       const data = itemData.tag.split("-");
      //       newData.push({
      //         id: doc.id,
      //         title: data[0],
      //         description: data[1],
      //       });
      //     });
      //     console.log(newData, "new data");
      //     setData(
      //       _.sortBy(newData, function (i) {
      //         return i.title.toLowerCase();
      //       })
      //     );
      //   });
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    fetchTags();
  }, []);

  const handleAddProduct = async (value) => {
    setLoading(true);
    const res = await createTag(value);
    setIsModalVisible(false);
    message.success(res);
    setLoading(false);
    fetchTags();
    // console.log(value);
    // const id = uuidv4();
    // db.collection("tags")
    //   .doc(id)
    //   .set({
    //     tag: `${value.title} - ${value.description}`,
    //   })
    //   .then(async (doc) => {
    //     setIsModalVisible(false);
    //     message.success("Tag created!");
    //     setLoading(false);
    //     fetchTags();
    //   })
    //   .catch(function (error) {
    //     setIsModalVisible(false);
    //     message.error("Tag not created!");
    //     setLoading(false);
    //     console.log(error);
    //   });
  };
  const handleEditProduct = async (value) => {
    setLoading(true);

    const res = await updateTag(value);
    setEditModalVisible(false);
    message.success(res);
    setLoading(false);
    fetchTags();
    // console.log(value);
    // db.collection("tags")
    //   .doc(value.id)
    //   .update({
    //     tag: `${value.title} - ${value.description}`,
    //   })
    //   .then(async (doc) => {
    //     setEditModalVisible(false);
    //     message.success("Tag updated!");
    //     setLoading(false);
    //     fetchTags();
    //   })
    //   .catch(function (error) {
    //     setEditModalVisible(false);
    //     message.error("Tag not updated!");
    //     setLoading(false);
    //     console.log(error);
    //   });
  };
  const handleSearch = (keyword) => {
    console.log(keyword);
    if (keyword.length) {
      const newData = data.filter((item) => {
        if (
          item.description.toLowerCase().includes(keyword.toLowerCase()) ||
          item.title.toLowerCase().includes(keyword.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });

      setData(newData);
    } else {
      fetchTags();
    }
  };
  return (
    <div className="App">
      <ModalForm
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      >
        <Form name="control-ref" layout="vertical" onFinish={handleAddProduct}>
          <Form.Item
            name="title"
            rules={[{ required: true }]}
            label={`Tag`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            rules={[{ required: true }]}
            label={`Description`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>

          <Row justify="center">
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              style={{ width: "80%" }}
            >
              Submit
            </Button>
          </Row>
        </Form>
      </ModalForm>
      <ModalForm
        isModalVisible={editModalVisible}
        setIsModalVisible={setEditModalVisible}
      >
        <Form
          name="edit-fomr"
          layout="vertical"
          form={form}
          onFinish={handleEditProduct}
        >
          <Form.Item
            name="title"
            rules={[{ required: true }]}
            label={`Tag`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            rules={[{ required: true }]}
            label={`Description`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ display: "none" }}
            name="id"
            rules={[{ required: true }]}
            label={`ID`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>
          <Row justify="center">
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              style={{ width: "80%" }}
            >
              Submit
            </Button>
          </Row>
        </Form>
      </ModalForm>

      <Form name="control-ref">
        <Row justify="space-between">
          <Col span={8}>
            <Form.Item name="name">
              <Search placeholder={`Search Name`} onSearch={handleSearch} />
            </Form.Item>
          </Col>

          <Col span={2}>
            <Button
              type="ghost"
              onClick={() => {
                setIsModalVisible(true);
              }}
            >
              Create
            </Button>
          </Col>
        </Row>
      </Form>
      <div
        style={{
          flexDirection: "row",
          justifyContent: "center",
          marginLeft: 20,
          marginRight: 20,
        }}
      >
        <Table
          // {...tableProps}
          pagination={{ position: ["topRight"] }}
          dataSource={data}
          // className={styles.table}
          bordered
          scroll={{ y: 575 }}
          columns={columns}
          simple
          rowKey={(record) => record.id}
        />
      </div>
    </div>
  );
};

export default Tags;
