import React, { useState, useRef, useEffect } from "react";
import {
  Table,
  Modal,
  Button,
  Row,
  Col,
  DatePicker,
  Form,
  Input,
  Select,
  message,
} from "antd";
import { Link } from "react-router-dom";
import DropOption from "../components/DropOption";
import ModalForm from "../components/ModalForm";
import { useDispatch, useSelector } from "react-redux";
import { delUsers, postUsers, putUsers } from "../store/actions";
import { useAuth } from "../contexts/AuthContext";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";

import { auth, db, googleProvider } from "../firebase";
const { confirm } = Modal;
const { Search } = Input;
const { RangePicker } = DatePicker;

const Users = () => {
  const formItemLayout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 14,
    },
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "displayName",
      key: "displayName",
      width: "10%",
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "description",
      width: "20%",
    },

    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: "8%",
      render: (text, record) => {
        return (
          <DropOption
            onMenuClick={(e) => handleMenuClick(record, e)}
            menuOptions={[
              { key: "1", name: `Update` },
              { key: "2", name: `Delete` },
            ]}
          />
        );
      },
    },
  ];

  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);

  const [editModalVisible, setEditModalVisible] = useState(false);
  const [uid, setUid] = useState();
  const { currentUser } = useAuth();
  const [data, setData] = useState([]);

  const fetchUserName = async () => {
    try {
      const res = await axios.get(
        "https://us-central1-scout-ede1c.cloudfunctions.net/app/user/get"
      );
      console.log(res);
      setData(res.data.users);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    fetchUserName();
  }, []);

  const handleMenuClick = (record, e) => {
    if (e.key === "1") {
      console.log(record);
      setEditModalVisible(true);
      // setUid(record._id);
      form.setFieldsValue({
        name: record.displayName,
        email: record.email,
        id: record.uid,
      });

      // setEditRecord(record)
    } else if (e.key === "2") {
      confirm({
        title: `Are you sure delete this record?`,
        onOk() {
          const data = { uid: record.uid };
          const config = {
            method: "delete",
            url: `https://us-central1-scout-ede1c.cloudfunctions.net/app/user/delete`,
            data,
            headers: {},
          };
          axios(config)
            .then(function (response) {
              // db.collection("users").doc(record.uid).delete();
              fetchUserName();
              message.success("Successfully delete!");
            })
            .catch(function (error) {});

          // console.log(record.id);
          // dispatch(delUsers(record._id));
        },
      });
    }
  };

  const { Option } = Select;
  const handleAddUser = async (value) => {
    console.log(value);
    const data = {
      email: value.email,
      password: value.password,
      displayName: value.name,
    };
    setLoading(true);
    try {
      await axios
        .post(
          "https://us-central1-scout-ede1c.cloudfunctions.net/app/user/create",
          data
        )
        .then((res) => {
          console.log(res.data.uid);
          if (value.role === "admin") {
            db.collection("admins")
              .doc(res.data.uid)
              .set({ email: value.email });
          }
          fetchUserName();
          setLoading(false);
          setIsModalVisible(false);
        });
    } catch (error) {
      message.error(error.response.data.message);
      setLoading(false);
    }
  };

  const handleEditUser = async (value) => {
    const data = {
      email: value.email,
      uid: value.id,
      displayName: value.name,
    };
    const config = {
      method: "put",
      url: `https://us-central1-scout-ede1c.cloudfunctions.net/app/user/update`,
      data,
      headers: {},
    };
    setLoading(true);
    axios(config)
      .then(function (response) {
        console.log("res", response);
        if (value.role === "admin") {
          db.collection("admins").doc("admin_doc").doc().update({
            email: value.email,
          });
        }
        fetchUserName();
        setLoading(false);
        setEditModalVisible(false);
      })
      .catch(function (error) {
        message.error(error.response.data.message);
        setLoading(false);
        console.log(`${error.response.data.message}`);
      });
  };

  return (
    <div className="App">
      <ModalForm
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      >
        <Form name="control-ref" layout="horizontal" onFinish={handleAddUser}>
          <Form.Item
            name="name"
            rules={[{ required: true }]}
            label={`Name`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
            hasFeedback
            {...formItemLayout}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="confirm"
            label="Confirm"
            dependencies={["password"]}
            {...formItemLayout}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[{ required: true }, { type: "email" }]}
            label={`Email`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="role"
            rules={[{ required: true }]}
            label={`Role`}
            hasFeedback
            {...formItemLayout}
          >
            <Select
              size="large"
              allowClear
              style={{ width: "100%" }}
              placeholder="Select a role"
            >
              <Option value="admin">admin</Option>
              <Option value="user">user</Option>
            </Select>
          </Form.Item>

          <Row justify="center">
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              style={{ width: "80%" }}
            >
              Add User
            </Button>
          </Row>
        </Form>
      </ModalForm>

      <ModalForm
        isModalVisible={editModalVisible}
        setIsModalVisible={setEditModalVisible}
      >
        <Form
          name="edit-form"
          form={form}
          layout="horizontal"
          onFinish={handleEditUser}
        >
          <Form.Item
            name="name"
            rules={[{ required: true }]}
            label={`Name`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ display: "none" }}
            name="id"
            rules={[{ required: true }]}
            label={`ID`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
            hasFeedback
            {...formItemLayout}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="confirm"
            label="Confirm"
            dependencies={["password"]}
            {...formItemLayout}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[{ required: true }, { type: "email" }]}
            label={`Email`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>

          <Row justify="center">
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              style={{ width: "80%" }}
            >
              Edit User
            </Button>
          </Row>
        </Form>
      </ModalForm>
      <Form name="control-ref">
        <Row justify="space-between">
          <Col span={8}>
            <Form.Item name="name">
              <Search
                placeholder={`Search Name`}
                // onSearch={this.handleSubmit}
              />
            </Form.Item>
          </Col>

          <Col span={2}>
            <Button type="ghost" onClick={() => setIsModalVisible(true)}>
              Create
            </Button>
          </Col>
        </Row>
      </Form>
      <div
        style={{
          flexDirection: "row",
          justifyContent: "center",
          marginLeft: 20,
          marginRight: 20,
        }}
      >
        <Table
          // {...tableProps}
          pagination={{ position: ["topRight"] }}
          dataSource={data}
          // className={styles.table}
          bordered
          scroll={{ y: 575 }}
          columns={columns}
          simple
          rowKey={(record) => record.id}
        />
      </div>
    </div>
  );
};

export default Users;
