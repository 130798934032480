import React, { useEffect } from "react";
import LayoutSide from "./Pages/LayoutSide";
import Login from "./Pages/Login";
import PrivateRoute from "./PrivateRoute";

import { AuthProvider } from "./contexts/AuthContext";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <Switch>
          <PrivateRoute path="/dashboard" component={LayoutSide} />
          {/* <Route path="/signup" component={Signup} /> */}
          <Route exact path="/" component={Login} />
          {/* <Route path="/forgot-password" component={ForgotPassword} /> */}
        </Switch>
      </AuthProvider>
    </Router>
  );
};

export default App;
