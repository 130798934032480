import React, { useState, useEffect } from "react";
import {
  Table,
  Modal,
  Button,
  Row,
  Col,
  Form,
  Input,
  message,
  Avatar,
  Image,
  Progress,
  Select,
} from "antd";
import DropOption from "../components/DropOption";
import ModalForm from "../components/ModalForm";
import { useHistory } from "react-router-dom";

import { v4 as uuidv4 } from "uuid";
import { db, storage } from "../firebase";
import { getGuide } from "../utils/guideApi";
import { getPatch, updatePatch } from "../utils/patchesApi";
const { confirm } = Modal;
const { Search } = Input;
const { Option } = Select;

const Sets = () => {
  const history = useHistory();
  const formItemLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "10%",
    },
    {
      title: "Image",
      dataIndex: "image_url",
      key: "image_url",
      width: "10%",
      render: (text, record) => <Avatar size={64} src={<Image src={text} />} />,
    },
    {
      title: "Reference",
      dataIndex: "reference",
      key: "reference",
      width: "10%",
    },
    // {
    //   title: "Patches",
    //   dataIndex: "patches",
    //   key: "patches",
    //   width: "10%",
    //   render: (text, record) => (
    //     <button onClick={() => history.push({ pathname: "/dashboard/patches", state: record.id })}>View Patches</button>
    //   ),
    // },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: "8%",
      render: (text, record) => {
        return (
          <DropOption
            onMenuClick={(e) => handleMenuClick(record, e)}
            menuOptions={[
              { key: "1", name: `Update` },
              { key: "2", name: `Delete` },
            ]}
          />
        );
      },
    },
  ];

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [guideModalVisible, setGuideModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState();
  const [showProgress, setShowProgress] = useState(false);
  const [image, setImage] = useState();
  const [data, setData] = useState([]);
  const [guides, setGuides] = useState([]);
  const [patches, setPatches] = useState([]);
  const [guideID, setGuideID] = useState();
  const [form] = Form.useForm();

  const handleMenuClick = (record, e) => {
    if (e.key === "1") {
      setEditModalVisible(true);
      setShowProgress(false);
      setImage(null);
      setProgress(0);
      // setPatches(record.patches);
      //   setGuides([]);
      setGuideID(null);
      console.log(record);
      form.setFieldsValue({
        id: record.id,
        name: record.name,
        ref: record.reference,
      });
    } else if (e.key === "2") {
      confirm({
        title: `Are you sure you want to delete this item?`,
        async onOk() {
          fetchGuides();
          const selectedGuide = await guides.find(
            (item) => item.id === record.guideID
          );

          const guideSetUrls = await selectedGuide.setUrls.filter((item) => {
            return item !== record.image_url;
          });
          const patches = await record.patches.map((item) => {
            updatePatch({ id: item, setImageUrl: "" });
          });
          db.collection("newGuide")
            .doc(record.guideID)
            .update({ setUrls: guideSetUrls });
          db.collection("sets").doc(record.id).delete();
          storage.refFromURL(record.image_url).delete();
          // console.log(patches);
          fetchSets();
          message.success("Successfully delete!");
        },
      });
    }
  };

  const fetchSets = async () => {
    let newData = [];
    try {
      db.collection("sets")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const itemData = doc.data();
            newData.push({ ...itemData, id: doc.id });
          });
          console.log(newData, "new data");
          setData(newData);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const fetchGuides = async () => {
    const res = await getGuide();
    setGuides(res);
    let newData = [];
    // try {
    //   db.collection("guides")
    //     .get()
    //     .then((querySnapshot) => {
    //       querySnapshot.forEach((doc) => {
    //         const itemData = doc.data();
    //         newData.push({
    //           id: doc.id,
    //           title: itemData.title,
    //           image_url: itemData.image_url,
    //           subtitle: itemData.subtitle,
    //           description: itemData.description,
    //           updatedDate: itemData.updatedDate,
    //           price: itemData.price,
    //           patches: itemData.patches,
    //           published: itemData.published,
    //           setUrls: itemData.setUrls,
    //         });
    //       });
    //       console.log(newData, "new data");
    //       setGuides(newData);
    //     });
    // } catch (err) {
    //   console.error(err);
    // }
  };

  useEffect(() => {
    fetchSets();
    fetchGuides();
  }, []);

  const getPatches = async () => {
    if (guideID) {
      const newData = guides.find((guide) => guide.id === guideID);
      const patches = await getPatch(newData.id);
      setPatches(patches);
    }
  };
  useEffect(() => {
    getPatches();
  }, [guideID]);

  const handleAddProduct = async (value) => {
    try {
      setLoading(true);
      const selectedGuide = guides.find((guide) => guide.id === guideID);
      const res = await db.collection("sets").add({
        guideID: guideID,
        name: value.name,
        reference: value.ref,
        patches: value.patches,
        // patches.filter((patch) =>
        //   value.patches.some((item) => item === patch.name)
        // ),
      });

      if (image) {
        setShowProgress(true);
        const resp = await storage.ref(`images/${image.name}`);
        const url = await resp.put(image).then(() => resp.getDownloadURL());
        await db
          .collection("sets")
          .doc(res.id)
          .update({
            image_url: url,
          })
          .then(() => {
            setProgress(100);
          });
        await db
          .collection("newGuide")
          .doc(guideID)
          .update({
            setUrls: [...selectedGuide.setUrls, url],
            // patches: patches.map((patch) => {
            //   value.patches.some((item) => {
            //     if (item === patch.name) {
            //       patch.setImageUrl = url;
            //     }
            //   });
            //   return patch;
            // }),
          });
        value.patches.map((pId) => {
          db.collection("newPatches").doc(pId).update({ setImageUrl: url });
        });
      }
      setIsModalVisible(false);
      message.success("Set created!");
      setLoading(false);
      fetchSets();
      setImage(null);
      setProgress(0);
      setPatches([]);
      setGuideID(null);
      form.resetFields();
      setLoading(false);
      fetchGuides();
    } catch (error) {
      setIsModalVisible(false);
      message.error("Set not created!");
      setLoading(false);
      console.log(error);
    }
  };
  const handleEditProduct = async (value) => {
    setLoading(true);
    console.log(value);
    db.collection("sets")
      .doc(value.id)
      .update({ name: value.name, reference: value.ref })
      .then(async (doc) => {
        setEditModalVisible(false);
        message.success("Sets updated!");
        setLoading(false);
        fetchSets();
      })
      .catch(function (error) {
        setEditModalVisible(false);
        message.error("Sets not updated!");
        setLoading(false);
        console.log(error);
      });
  };
  const handleSearch = (keyword) => {
    console.log(keyword);
    if (keyword.length) {
      const newData = data.filter((item) => {
        if (
          item.reference.toLowerCase().includes(keyword.toLowerCase()) ||
          item.name.toLowerCase().includes(keyword.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });

      setData(newData);
    } else {
      fetchSets();
    }
  };
  return (
    <div className="App">
      <ModalForm
        isModalVisible={guideModalVisible}
        setIsModalVisible={setGuideModalVisible}
      >
        <Row gutter={16} style={{ padding: "2em 0em" }}>
          {guides.map((item) => (
            <Col
              md={12}
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => {
                console.log(item.id);
                setGuideID(item.id);
                setGuideModalVisible(false);
                setIsModalVisible(true);
              }}
            >
              <img src={item.image_url} alt="" style={{ width: "100%" }} />
              <h4>{item.title}</h4>
            </Col>
          ))}
        </Row>
      </ModalForm>
      <ModalForm
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      >
        <Form
          form={form}
          name="control-ref"
          layout="vertical"
          onFinish={handleAddProduct}
        >
          <Form.Item
            name="image"
            rules={[{ required: image ? false : true }]}
            label={`Set Image`}
            hasFeedback
            {...formItemLayout}
          >
            <input type="file" onChange={(e) => setImage(e.target.files[0])} />
            {showProgress ? (
              <Progress percent={progress} showInfo={false} />
            ) : (
              ""
            )}
          </Form.Item>
          <Form.Item
            name="name"
            rules={[{ required: true }]}
            label={`Name`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="ref"
            rules={[{ required: true }]}
            label={`Internal Reference`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="patches"
            rules={[{ required: false }]}
            label={`Patches`}
            hasFeedback
            {...formItemLayout}
          >
            <Select
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              placeholder="Please select"
            >
              {patches.map((item, i) => (
                <Option key={i} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Row justify="center">
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              style={{ width: "80%" }}
            >
              Submit
            </Button>
          </Row>
        </Form>
      </ModalForm>
      <ModalForm
        isModalVisible={editModalVisible}
        setIsModalVisible={setEditModalVisible}
      >
        <Form
          name="edit-fomr"
          layout="vertical"
          form={form}
          onFinish={handleEditProduct}
        >
          <Form.Item
            name="name"
            rules={[{ required: true }]}
            label={`Name`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="ref"
            rules={[{ required: true }]}
            label={`Internal Reference`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ display: "none" }}
            name="id"
            rules={[{ required: true }]}
            label={`ID`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>
          <Row justify="center">
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              style={{ width: "80%" }}
            >
              Submit
            </Button>
          </Row>
        </Form>
      </ModalForm>

      <Form name="control-ref">
        <Row justify="space-between">
          <Col span={8}>
            <Form.Item name="name">
              <Search placeholder={`Search Name`} onSearch={handleSearch} />
            </Form.Item>
          </Col>

          <Col span={2}>
            <Button
              type="ghost"
              onClick={() => {
                setGuideModalVisible(true);
              }}
            >
              Create
            </Button>
          </Col>
        </Row>
      </Form>
      <div
        style={{
          flexDirection: "row",
          justifyContent: "center",
          marginLeft: 20,
          marginRight: 20,
        }}
      >
        <Table
          // {...tableProps}
          pagination={{ position: ["topRight"] }}
          dataSource={data}
          // className={styles.table}
          bordered
          scroll={{ y: 575 }}
          columns={columns}
          simple
          rowKey={(record) => record.id}
        />
      </div>
    </div>
  );
};

export default Sets;
