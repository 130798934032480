import React from "react";
import * as functions from "firebase-functions";
import * as admin from "firebase-admin";
import { debug } from "firebase-functions/lib/logger";
// const admin = require("firebase-admin");

admin.initializeApp();

export const SendTopic = (docId) => {
  functions.firestore
    .document(`guides/${docId}`)
    .onUpdate(async (snapshot, context) => {
      if (
        snapshot.after.data().published == true &&
        snapshot.before.data().published == false
      ) {
        admin.messaging().sendToTopic("allUsers", {
          notification: {
            title: "🎁 New Patch Pocket Guide!",
            body:
              "A new guide for " +
              snapshot.after.data().title +
              " has been published",
            icon: snapshot.after.data().image_url,
          },
          data: {
            route: snapshot.after.data().title,
          },
        });
      }
      
      if (
        snapshot.after.data().published == true &&
        snapshot.before.data().published == true &&
        snapshot.after.data().patches.length >
          snapshot.before.data().patches.length
      ) { 
        admin.messaging().sendToTopic(snapshot.after.id, {
          notification: {
            title: "👏 Your Patch Pocket Guide has a new item!",
            body:
              "Your Guide " +
              snapshot.after.data().title +
              " has added " +
              snapshot.after.data().patches[
                snapshot.after.data().patches.length - 1
              ].name,
            icon: snapshot.after.data().image_url,
          },
          data: {
            route: snapshot.after.data().title,
          },
        });
      }
    });
};
