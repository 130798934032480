import { db } from "../firebase";

const colorRef = db.collection("newColor");

export const getColor = (data) =>
  new Promise(async (resolve, reject) => {
    try {
      let temp = [];
      const snap = await colorRef.orderBy("name", "asc").get();
      snap.forEach((doc) => temp.push({ id: doc.id, ...doc.data() }));
      console.log(temp);
      return resolve(temp);
    } catch (error) {
      return reject(error);
    }
  });

export const createColor = (data) =>
  new Promise(async (resolve, reject) => {
    try {
      const color = await colorRef.add(data);

      return resolve("successfully created!");
    } catch (error) {
      return reject(error);
    }
  });

export const updateColor = (data) =>
  new Promise(async (resolve, reject) => {
    try {
      const color = await colorRef.doc(data.id).update(data);

      return resolve("successfully updated!");
    } catch (error) {
      return reject(error);
    }
  });

export const deleteColor = (data) =>
  new Promise(async (resolve, reject) => {
    try {
      await colorRef.doc(data).delete();

      return resolve("successfully deleted!");
    } catch (error) {
      return reject(error);
    }
  });
