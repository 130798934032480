import React, { useState, useEffect } from "react";
import {
  Table,
  Modal,
  Avatar,
  Button,
  Row,
  Col,
  DatePicker,
  Form,
  Input,
  Select,
  Upload,
  message,
  Progress,
  Popover,
  Image,
} from "antd";
import { CloseCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import DropOption from "../components/DropOption";
import ModalForm from "../components/ModalForm";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { auth, db, storage } from "../firebase";
import { SendTopic } from "../hooks/SendTopic";
import {
  getGuide,
  createGuide,
  updateGuide,
  deleteGuide,
} from "../utils/guideApi";

const { confirm } = Modal;
const { Search } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;

const Guides = () => {
  const history = useHistory();
  const formItemLayout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 14,
    },
  };
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: "10%",
    },
    {
      title: " Subtitle",
      dataIndex: "subtitle",
      key: "subtitle",
      width: "10%",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "20%",
      render: (text) => (
        <>
          <p className="hideText ">{text} </p>
          {text.length >= 80 ? (
            <Popover
              content={text}
              trigger="click"
              placement="rightTop"
              arrowPointAtCenter
            >
              <a href="#">more</a>
            </Popover>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      title: " Image",
      dataIndex: "image_url",
      key: "image_url",
      width: "10%",
      render: (text) => (
        <Avatar
          size={64}
          style={{ marginLeft: 8 }}
          src={<Image src={text} />}
        />
      ),
    },
    {
      title: "Updated Date",
      dataIndex: "datetime",
      key: "datetime",
      width: "10%",
      // render: (text) => <p>{moment(text).format("MM-DD-YYYY")}</p>,
      render: (text) => (
        <p className="mb-0">{moment(text, "X").format("MM-DD-YYYY")}</p>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      width: "10%",
    },
    {
      title: "Patch",
      dataIndex: "patches",
      key: "patches",
      width: "10%",
      render: (text, record) => (
        <button
          onClick={() =>
            history.push({ pathname: "/dashboard/patches", state: record.id })
          }
        >
          View Patches
        </button>
      ),
    },
    {
      title: "Sets",
      dataIndex: "setUrls",
      key: "setUrls",
      width: "10%",
      render: (text, record) => (
        <Avatar.Group
          maxCount={1}
          size={64}
          maxStyle={{
            color: "#f56a00",
            backgroundColor: "#fde3cf",
          }}
        >
          {text.map((item) => (
            <Avatar
              size={64}
              src={
                <Image
                  src={item}
                  // style={{
                  //   width: 32,
                  // }}
                />
              }
            />
          ))}
        </Avatar.Group>
      ),
    },
    {
      title: "Published",
      dataIndex: "published",
      key: "published",
      width: "7%",
      render: (text, record) => (
        <div style={{ textAlign: "center" }}>
          {text ? (
            <CheckCircleOutlined
              style={{ fontSize: "1.5em", color: "#52C41A" }}
            />
          ) : (
            <CloseCircleOutlined
              style={{ fontSize: "1.5em", color: "#FF4D4F" }}
            />
          )}
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: "8%",
      render: (text, record) => {
        return (
          <DropOption
            onMenuClick={(e) => handleMenuClick(record, e)}
            menuOptions={[
              { key: "1", name: `Update` },
              { key: "2", name: `Delete` },
              { key: "3", name: "Publish" },
              { key: "4", name: "Unpublish" },
            ]}
          />
        );
      },
    },
  ];

  const [selectedRow, setSelectedRow] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [progress, setProgress] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const [image, setImage] = useState("");
  const [uid, setUid] = useState();
  const [form] = Form.useForm();
  const { TextArea } = Input;

  const handleMenuClick = (record, e) => {
    if (e.key === "1") {
      setShowProgress(false);
      setImage(null);
      setProgress(0);
      setEditModalVisible(true);
      console.log(record);
      form.setFieldsValue({
        id: record.id,
        title: record.title,
        image: record.image_url,
        subtitle: record.subtitle,
        description: record.description,
        price: record.price,
      });
    } else if (e.key === "2") {
      confirm({
        title: `Are you sure you want to delete this item?`,
        onOk() {
          db.collection("newGuide").doc(record.id).delete();
          storage.refFromURL(record.image_url).delete();

          fetchGuides();
          message.success("Successfully delete!");
        },
      });
    } else if (e.key === "3") {
      db.collection("newGuide").doc(record.id).update({
        published: true,
      });
      fetchGuides();
      message.success("Successfully Publish!");
    } else if (e.key === "4") {
      db.collection("newGuide").doc(record.id).update({
        published: false,
      });
      fetchGuides();
      message.success("Successfully Unpublish!");
    }
  };

  const fetchGuides = async () => {
    let newData = [];
    try {
      const res = await getGuide();
      console.log(res);
      setData(res);
      // db.collection("guides")
      //   .get()
      //   .then((querySnapshot) => {
      //     querySnapshot.forEach((doc) => {
      //       const itemData = doc.data();
      //       newData.push({
      //         id: doc.id,
      //         title: itemData.title,
      //         image_url: itemData.image_url,
      //         subtitle: itemData.subtitle,
      //         description: itemData.description,
      //         updatedDate: itemData.datetime,
      //         price: itemData.price,
      //         patches: itemData.patches,
      //         published: itemData.published,
      //         setUrls: itemData.setUrls,
      //       });
      //     });
      //     console.log(newData, "new data");
      //     setData(newData);
      //   });
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    fetchGuides();
  }, []);

  const handleAddProduct = async (value) => {
    setLoading(true);
    console.log(value);
    const res = await createGuide(
      {
        title: value.title,
        subtitle: value.subtitle,
        description: value.description,
        price: value.price,
      },
      image
    );
    setIsModalVisible(false);
    message.success(res);
    setLoading(false);
    fetchGuides();
    setImage(null);
    // const id = uuidv4();
    // console.log(id);
    // db.collection("guides")
    //   .doc(id)
    //   .set({
    //     title: value.title,
    //     subtitle: value.subtitle,
    //     description: value.description,
    //     price: value.price,
    //     published: false,
    //     datetime: Number(moment(new Date()).format("X")),
    //     patches: [],
    //     setUrls: [],
    //   })
    //   .then(async (doc) => {
    //     if (image) {
    //       setShowProgress(true);
    //       const resp = await storage.ref(`images/${image.name}`);
    //       const url = await resp.put(image).then(() => resp.getDownloadURL());
    //       await db
    //         .collection("guides")
    //         .doc(id)
    //         .update({
    //           image_url: url,
    //         })
    //         .then(() => {
    //           setProgress(100);
    //         });
    //     }
    //     form.resetFields(["title", "subtitle", "description", "price"]);
    //     setIsModalVisible(false);
    //     setProgress(0);
    //     setShowProgress(false);
    //     setImage(null);
    //     message.success("Guide created!");
    //     setLoading(false);
    //     fetchGuides();
    //     // SendTopic(id);
    //   })
    //   .catch(function (error) {
    //     setIsModalVisible(false);
    //     message.error("Guide not created!");
    //     setLoading(false);
    //     console.log(error);
    //   });

    // const sendToTopic = functions.httpsCallable("sendToTopic");
    // sendToTopic(id);
  };
  const handleEditProduct = async (value) => {
    setLoading(true);
    console.log(value);
    const res = await updateGuide(
      {
        id: value.id,
        title: value.title,
        subtitle: value.subtitle,
        description: value.description,
        price: value.price,
        datetime: Number(value.datetime.format("X")),
      },
      image
    );
    setEditModalVisible(false);
    message.success(res);
    setLoading(false);
    fetchGuides();
    // db.collection("guides")
    //   .doc(value.id)
    //   .update({
    //     title: value.title,
    //     subtitle: value.subtitle,
    //     description: value.description,
    //     price: value.price,
    //     datetime: Number(value.datetime.format("X")),
    //   })
    //   .then(async (doc) => {
    //     if (image) {
    //       setShowProgress(true);
    //       storage.refFromURL(value.image).delete();
    //       const resp = await storage.ref(`images/${image.name}`);
    //       const url = await resp.put(image).then(() => resp.getDownloadURL());
    //       await db
    //         .collection("guides")
    //         .doc(value.id)
    //         .update({
    //           image_url: url,
    //         })
    //         .then(() => {
    //           setProgress(100);
    //         });
    //     }
    //     form.resetFields(["title", "subtitle", "description", "price"]);
    //     setEditModalVisible(false);
    //     message.success("Guide updated!");
    //     setLoading(false);
    //     fetchGuides();
    //   })
    //   .catch(function (error) {
    //     setEditModalVisible(false);
    //     message.error("Guide not updated!");
    //     setLoading(false);
    //     console.log(error);
    //   });
  };
  const handleSearch = (keyword) => {
    console.log(keyword);
    if (keyword.length) {
      const newData = data.filter((item) => {
        if (
          item.description.toLowerCase().includes(keyword.toLowerCase()) ||
          item.title.toLowerCase().includes(keyword.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });

      setData(newData);
    } else {
      fetchGuides();
    }
  };

  console.log(Number(moment(new Date()).format("X")));
  return (
    <div className="App">
      <ModalForm
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      >
        <Form
          form={form}
          name="control-ref"
          layout="horizontal"
          onFinish={handleAddProduct}
        >
          <Form.Item
            name="title"
            rules={[{ required: true }]}
            label={`Guide Title`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="subtitle"
            rules={[{ required: true }]}
            label={`Guide SubTitle`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="price"
            rules={[{ required: true }]}
            label={`Price`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="image"
            rules={[{ required: image ? false : true }]}
            label={`Guide Image`}
            hasFeedback
            {...formItemLayout}
          >
            <input type="file" onChange={(e) => setImage(e.target.files[0])} />
            {showProgress ? (
              <Progress percent={progress} showInfo={false} />
            ) : (
              ""
            )}
          </Form.Item>
          <Form.Item
            name="description"
            rules={[{ required: true }]}
            label={`Guide Description`}
            hasFeedback
            {...formItemLayout}
          >
            <TextArea rows={4} />
          </Form.Item>

          <Row justify="center">
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              style={{ width: "80%" }}
            >
              Submit
            </Button>
          </Row>
        </Form>
      </ModalForm>
      <ModalForm
        isModalVisible={editModalVisible}
        setIsModalVisible={setEditModalVisible}
      >
        <Form
          name="edit-fomr"
          layout="horizontal"
          form={form}
          onFinish={handleEditProduct}
        >
          <Form.Item
            name="title"
            rules={[{ required: true }]}
            label={`Guide Title`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ display: "none" }}
            name="id"
            rules={[{ required: true }]}
            label={`ID`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="subtitle"
            rules={[{ required: true }]}
            label={`Guide SubTitle`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="price"
            rules={[{ required: true }]}
            label={`Price`}
            hasFeedback
            {...formItemLayout}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="image"
            rules={[{ required: image ? false : true }]}
            label={`Guide Image`}
            hasFeedback
            {...formItemLayout}
          >
            <input type="file" onChange={(e) => setImage(e.target.files[0])} />
            {showProgress ? (
              <Progress percent={progress} showInfo={false} />
            ) : (
              ""
            )}
          </Form.Item>
          <Form.Item
            name="description"
            rules={[{ required: true }]}
            label={`Guide Description`}
            hasFeedback
            {...formItemLayout}
          >
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item
            name="datetime"
            rules={[{ required: true }]}
            label="Updated Date"
            hasFeedback
            {...formItemLayout}
          >
            <DatePicker />
          </Form.Item>
          <Row justify="center">
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              style={{ width: "80%" }}
            >
              Submit
            </Button>
          </Row>
        </Form>
      </ModalForm>
      <Form name="control-ref">
        <Row justify="space-between">
          <Col span={8}>
            <Form.Item name="name">
              <Search placeholder={`Search Name`} onSearch={handleSearch} />
            </Form.Item>
          </Col>

          <Col span={2}>
            <Button
              type="ghost"
              onClick={() => {
                setIsModalVisible(true);
              }}
            >
              Create
            </Button>
          </Col>
        </Row>
      </Form>
      <div
        style={{
          flexDirection: "row",
          justifyContent: "center",
          marginLeft: 20,
          marginRight: 20,
        }}
      >
        <Table
          // {...tableProps}
          pagination={{ position: ["topRight"] }}
          dataSource={data}
          // className={styles.table}
          bordered
          scroll={{ y: 575 }}
          columns={columns}
          simple
          rowKey={(record) => record.id}
          // rowSelection={{
          //   type: "radio",
          //   onChange: (keys, record) => setSelectedRow(record),
          // }}
        />
      </div>
    </div>
  );
};

export default Guides;
