import React, { useState, useRef, useEffect } from "react";
import { Button, Row, Input, Form, message } from "antd";
import { PoweroffOutlined } from "@ant-design/icons";
import { auth, db, storage } from "../firebase";

import styles from "../styles/Login.module.css";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { postAdmin } from "../store/actions";
import { useAuth } from "../contexts/AuthContext";

const Login = () => {
  const [admins, setAdmins] = useState([]);
  const history = useHistory();
  const FormItem = Form.Item;
  const { login } = useAuth();
  const [loading, setLoading] = useState(false);
  const handleLogin = async (value) => {
    console.log(value);
    try {
      setLoading(true);
      if (admins.includes(`%${value.email}`)) {
        await login(value.email, value.password);
        history.push("/dashboard");
      } else {
        message.error("You are not a admin");
      }
    } catch {
      message.error("Failed to log in");
    }
    setLoading(false);
  };
  useEffect(() => {
    db.collection("admins")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const itemData = doc.data();
          setAdmins(itemData.admin_list);
        });
      });
  }, []);

  return (
    <div>
      <div className={styles.form}>
        <div className={styles.logo}>
          <img src="/images/PatchPocket-side-Full.png" alt="" />
        </div>
        <Form onFinish={handleLogin}>
          <FormItem
            name="email"
            rules={[{ required: true }, { type: "email" }]}
            hasFeedback
          >
            <Input placeholder={`Email`} />
          </FormItem>
          <FormItem name="password" rules={[{ required: true }]} hasFeedback>
            <Input type="password" placeholder={`Password`} />
          </FormItem>
          <Row style={{ justifyContent: "center" }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Sign in
            </Button>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default Login;
