const type = {
  ADMIN_LOGIN: "ADMIN_LOGIN",
  GET_USERS: "GET_USERS",
  GET_PRODUCTS:'GET_PRODUCTS',
  GET_SERVICES:'GET_SERVICES',
  GET_DEALS:'GET_DEALS',
  GET_TICKETS:'GET_TICKETS',
  GET_FEEDBACKS:'GET_FEEDBACKS',
  GET_SERVICES_SUBSCRIPTIONS:'GET_SERVICES_SUBSCRIPTIONS',
  GET_TANSACTIONS:'GET_TANSACTIONS',
  GET_SINGLE_SERVICES_SUBSCRIPTIONS:'GET_SINGLE_SERVICES_SUBSCRIPTIONS'
};

export default type;
