import React, { useState, useRef, useEffect } from "react";
import { Card, Typography } from "antd";
import CountUp from "react-countup";
import axios from "axios";

import { auth, db, googleProvider } from "../firebase";

const Home = () => {
  const [guides, setGuides] = useState(0);
  const [patches, setPatches] = useState(0);
  const [sets, setSets] = useState(0);
  const [tags, setTags] = useState(0);
  const [borders, setBorders] = useState(0);
  const [users, setUsers] = useState(0);
  const [types, setTypes] = useState(0);
  const [colors, setColors] = useState(0);
  const { Title } = Typography;
  const fetchGuides = async () => {
    let newData = [];
    try {
      db.collection("newGuide")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const itemData = doc.data();
            newData.push({
              itemData,
            });
          });
          setGuides(newData.length);
        });
    } catch (err) {
      console.error(err);
    }
  };
  const fetchPatches = async () => {
    let newData = [];
    try {
      db.collection("newPatches")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const itemData = doc.data();
            newData.push({
              itemData,
            });
          });
          setPatches(newData.length);
        });
    } catch (err) {
      console.error(err);
    }
  };
  // const fetchUser = async () => {
  //   let newData = [];
  //   try {
  //     db.collection("users")
  //       .get()
  //       .then((querySnapshot) => {
  //         querySnapshot.forEach((doc) => {
  //           const itemData = doc.data();
  //           newData.push({
  //             itemData,
  //           });
  //         });
  //         setUsers(newData.length);
  //       });
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };
  const fetchUser = async () => {
    try {
      const res = await axios.get(
        "https://us-central1-scout-ede1c.cloudfunctions.net/app/user/get"
      );
      console.log(res);
      setUsers(res.data.users.length);
    } catch (err) {
      console.error(err);
    }
  };
  const fetchSets = async () => {
    let newData = [];
    try {
      db.collection("sets")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const itemData = doc.data();
            newData.push({
              itemData,
            });
          });
          setSets(newData.length);
        });
    } catch (err) {
      console.error(err);
    }
  };
  const fetchTags = async () => {
    let newData = [];
    try {
      db.collection("newTags")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const itemData = doc.data();
            newData.push({
              itemData,
            });
          });
          setTags(newData.length);
        });
    } catch (err) {
      console.error(err);
    }
  };
  const fetchBorders = async () => {
    try {
      let newData = [];
      db.collection("newBorder")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const itemData = doc.data();
            newData.push({
              itemData,
            });
          });
          setBorders(newData.length);
        });
    } catch (err) {
      console.error(err);
    }
  };
  const fetchTypes = async () => {
    try {
      let newData = [];
      db.collection("newTypes")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const itemData = doc.data();
            newData.push({
              itemData,
            });
          });
          setTypes(newData.length);
        });
    } catch (err) {
      console.error(err);
    }
  };
  const fetchColor = async () => {
    try {
      let newData = [];
      db.collection("newColor")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const itemData = doc.data();
            newData.push({
              itemData,
            });
          });
          setColors(newData.length);
        });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchGuides();
    fetchPatches();
    fetchUser();
    fetchSets();
    fetchBorders();
    fetchTypes();
    fetchColor();
    fetchTags();
  }, []);
  console.log(users);

  return (
    <div className="App">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          flexWrap: "wrap",
          marginLeft: 20,
          marginRight: 20,
        }}
      >
        <Card style={{ width: 300, margin: "1em", textAlign: "center" }}>
          <Title type="success">Guides</Title>
          <Title level={2}>
            {guides > 0 ? <CountUp duration="1" end={guides} /> : "checking..."}
          </Title>
        </Card>
        <Card style={{ width: 300, margin: " 1em", textAlign: "center" }}>
          <Title type="success">Patches</Title>
          <Title level={2}>
            {patches > 0 ? (
              <CountUp duration="2" end={patches} />
            ) : (
              "checking..."
            )}
          </Title>
        </Card>
        <Card style={{ width: 300, margin: " 1em", textAlign: "center" }}>
          <Title type="success">Sets</Title>
          <Title level={2}>
            {sets > 0 ? <CountUp duration="2" end={sets} /> : "checking..."}
          </Title>
        </Card>
        <Card style={{ width: 300, margin: " 1em", textAlign: "center" }}>
          <Title type="success">Users</Title>
          <Title level={2}>
            {users > 0 ? <CountUp duration="2" end={users} /> : "checking..."}
          </Title>
        </Card>
        <Card style={{ width: 300, margin: " 1em", textAlign: "center" }}>
          <Title type="success">Tags</Title>
          <Title level={2}>
            {tags > 0 ? <CountUp duration="2" end={tags} /> : "checking..."}
          </Title>
        </Card>
        <Card style={{ width: 300, margin: " 1em", textAlign: "center" }}>
          <Title type="success">Colors</Title>
          <Title level={2}>
            {colors > 0 ? <CountUp duration="2" end={colors} /> : "checking..."}
          </Title>
        </Card>
        <Card style={{ width: 300, margin: " 1em", textAlign: "center" }}>
          <Title type="success">Types</Title>
          <Title level={2}>
            {types > 0 ? <CountUp duration="2" end={types} /> : "checking..."}
          </Title>
        </Card>
        <Card style={{ width: 300, margin: " 1em", textAlign: "center" }}>
          <Title type="success">Borders</Title>
          <Title level={2}>
            {borders > 0 ? (
              <CountUp duration="2" end={borders} />
            ) : (
              "checking..."
            )}
          </Title>
        </Card>
      </div>
    </div>
  );
};

export default Home;
