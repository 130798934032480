import { db, storage } from "../firebase";
import moment from "moment";

const patchesRef = db.collection("newPatches");

export const getPatch = (data) =>
  new Promise(async (resolve, reject) => {
    try {
      let temp = [];
      const snap = await patchesRef
        .where("guideId", "==", data)
        .orderBy("sortingNumber", "asc")
        .get();
      snap.forEach((doc) => temp.push({ id: doc.id, ...doc.data() }));
      console.log(temp);
      return resolve(temp);
    } catch (error) {
      return reject(error);
    }
  });

export const createPatch = (data, image) =>
  new Promise(async (resolve, reject) => {
    try {
      const Patch = await patchesRef.add(data);
      if (image) {
        const image_url = [];
        for (let i = 0; i < image.length; i++) {
          const element = image[i];
          const storageRef = storage.ref(
            `images/${element.originFileObj.name}`
          );
          const donwloadUrl = await storageRef
            .put(element.originFileObj)
            .then(async () => {
              const url = await storageRef.getDownloadURL();
              return url;
            });
          image_url.push(donwloadUrl);
        }
        await patchesRef.doc(Patch.id).update({
          image_url: image_url,
        });
      }
      return resolve("successfully created!");
    } catch (error) {
      return reject(error);
    }
  });

export const updatePatch = (data, image) =>
  new Promise(async (resolve, reject) => {
    try {
      const Patch = await patchesRef.doc(data.id).update(data);

      if (image) {
        const image_url = [];
        for (let i = 0; i < image.length; i++) {
          const element = image[i];
          const storageRef = storage.ref(
            `images/${element.originFileObj.name}`
          );
          const donwloadUrl = await storageRef
            .put(element.originFileObj)
            .then(async () => {
              const url = await storageRef.getDownloadURL();
              return url;
            });
          image_url.push(donwloadUrl);
        }
        await patchesRef.doc(data.id).update({
          image_url: image_url,
        });
      }

      return resolve("successfully updated!");
    } catch (error) {
      return reject(error);
    }
  });

export const deletePatch = (data) =>
  new Promise(async (resolve, reject) => {
    try {
      await patchesRef.doc(data).delete();

      return resolve("successfully deleted!");
    } catch (error) {
      return reject(error);
    }
  });
