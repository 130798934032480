import React, { useState, useEffect } from "react";
import {
  Button,
  Row,
  Form,
  Input,
  Select,
  message,
  Progress,
  Upload,
} from "antd";
import ModalForm from "../components/ModalForm";
import { v4 as uuidv4 } from "uuid";
import { db, storage } from "../firebase";
import _ from "lodash";
import { getTag } from "../utils/tagsApi";
import { getBorder } from "../utils/borderApi";
import { getColor } from "../utils/colorApi";
import { getType } from "../utils/typesApi";
import { updatePatch } from "../utils/patchesApi";
import { UploadOutlined } from "@ant-design/icons";

const { Option } = Select;

export default function EditPatchModal({
  fetchPatches,
  showEditPatch,
  setShowEditPatch,
  guideId,
  // data,
  record,
}) {
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const [progress, setProgress] = useState();
  const [showProgress, setShowProgress] = useState(false);
  const [image, setImage] = useState();
  const [border, setBorder] = useState([]);
  const [color, setColor] = useState([]);
  const [type, setType] = useState([]);
  const [data, setData] = useState([]);
  const { TextArea } = Input;
  const [form] = Form.useForm();

  const formItemLayout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 14,
    },
  };

  // const fetchPatches = async () => {
  //   try {
  //     db.collection("guides")
  //       .get()
  //       .then((querySnapshot) => {
  //         querySnapshot.forEach((doc) => {
  //           const itemData = doc.data();
  //           if (itemData.patches && guideId === doc.id) {
  //             console.log(itemData);
  //             setData(itemData.patches);
  //           }
  //         });
  //       });
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  const props = {
    name: "file",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    headers: {
      authorization: "authorization-text",
    },

    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }

      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const handleChange = (event) => {
    const file = Array.from(event.target.files);
    setImage({ file });
  };

  const handleEditProduct = async (value) => {
    setLoading(true);
    console.log(value);
    const patch = {
      id: value.id,
      sortingNumber:
        value.sortingNumber !== undefined ? Number(value.sortingNumber) : null,
      name: value.issueCode !== undefined ? value.issueCode : null,
      bd_color: value.borderColor !== undefined ? value.borderColor : null,
      description: value.description !== undefined ? value.description : null,
      bd_type: value.borderType !== undefined ? value.borderType : null,
      type: value.type !== undefined ? value.type : null,
      bg_color:
        value.backgroundColor !== undefined ? value.backgroundColor : null,
      bsafld_color:
        value.bsafld_color !== undefined ? value.bsafld_color : null,
      name_color: value.nameColor !== undefined ? value.nameColor : null,
      year: value.yearIssued !== undefined ? value.yearIssued : null,
      quantity: value.quantity !== undefined ? value.quantity : null,
      tags: value.tags !== undefined ? value.tags : [],
      setImageUrl: "",
      image_url: value.image.fileList ? [] : value.image,
    };
    const res = await updatePatch(
      patch,
      value.image.fileList ? value.image.fileList : null
    );
    setShowEditPatch(false);
    message.success(res);
    setLoading(false);
    fetchPatches();
    form.resetFields();
    // console.log(value);
    // const image_url = [];
    // if (image) {
    //   setShowProgress(true);

    //   for (let i = 0; i < image.file.length; i++) {
    //     if (value.image[i]) {
    //       storage.refFromURL(value.image[i]).delete();
    //     }
    //     const element = image.file[i];
    //     const storageRef = storage.ref(`images/${element.name}`);
    //     const donwloadUrl = await storageRef.put(element).then(async () => {
    //       const url = await storageRef.getDownloadURL();
    //       return url;
    //     });
    //     image_url.push(donwloadUrl);
    //   }
    // }
    // console.log(data);
    // const patches = data.map((patch) => {
    //   console.log(patch.id);
    //   console.log(value.id);
    //   if (patch.id === value.id) {
    //     return {
    //       id: value.id,
    //       sortingNumber:
    //         value.sortingNumber !== undefined
    //           ? Number(value.sortingNumber)
    //           : null,
    //       name: value.issueCode !== undefined ? value.issueCode : null,
    //       bd_color: value.borderColor !== undefined ? value.borderColor : null,
    //       description:
    //         value.description !== undefined ? value.description : null,
    //       bd_type: value.borderType !== undefined ? value.borderType : null,
    //       type: value.type !== undefined ? value.type : null,
    //       bg_color:
    //         value.backgroundColor !== undefined ? value.backgroundColor : null,
    //       bsafld_color:
    //         value.bsafld_color !== undefined ? value.bsafld_color : null,
    //       name_color: value.nameColor !== undefined ? value.nameColor : null,
    //       year: value.yearIssued !== undefined ? value.yearIssued : null,
    //       quantity: value.quantity !== undefined ? value.quantity : null,
    //       tags: value.tags !== undefined ? value.tags : [],
    //       image_url: image_url.length ? image_url : patch.image_url,
    //       setImageUrl: patch.setImageUrl,
    //     };
    //   } else {
    //     return patch;
    //   }
    // });
    // console.log(patches);
    // db.collection("guides")
    //   .doc(guideId)
    //   .update({ patches: patches })
    //   .then(async (doc) => {
    //     setShowEditPatch(false);
    //     message.success("patch updated!");
    //     setLoading(false);
    //     fetchGuides();
    //     setShowProgress(false);
    //     setImage("");
    //   })
    //   .catch(function (error) {
    //     setShowEditPatch(false);
    //     message.error("patch not updated!");
    //     setLoading(false);
    //     console.log(error);
    //   });
  };

  const fetchTags = async () => {
    let newData = [];
    try {
      const res = await getTag();
      console.log(res);
      setTags(res);
      // db.collection("tags")
      //   .get()
      //   .then((querySnapshot) => {
      //     querySnapshot.forEach((doc) => {
      //       const itemData = doc.data();
      //       newData.push({
      //         id: doc.id,
      //         tag: itemData.tag,
      //       });
      //     });
      //     console.log(newData, "tags");
      //     setTags(
      //       _.sortBy(newData, function (i) {
      //         const lowercase = i.tag.split("-")[0].toLowerCase();
      //         return lowercase;
      //       })
      //     );
      //   });
    } catch (err) {
      console.error(err);
    }
  };
  const fetchBorder = async () => {
    let newData = [];
    try {
      const res = await getBorder();
      console.log(res);
      setBorder(res);
      // db.collection("borders")
      //   .get()
      //   .then((querySnapshot) => {
      //     querySnapshot.forEach((doc) => {
      //       const itemData = doc.data();
      //       newData = itemData.borders;
      //     });
      //     console.log(newData, "new data");
      //     setBorder(_.sortBy(newData));
      //   });
    } catch (err) {
      console.error(err);
    }
  };
  const fetchColor = async () => {
    let newData = [];
    try {
      const res = await getColor();
      console.log(res);
      setColor(res);
      // db.collection("colors")
      //   .get()
      //   .then((querySnapshot) => {
      //     querySnapshot.forEach((doc) => {
      //       const itemData = doc.data();
      //       newData = itemData.colors;
      //     });
      //     console.log(newData, "color");
      //     setColor(
      //       _.sortBy(newData, function (i) {
      //         const lowercase = i.split("-")[0].toLowerCase();
      //         return lowercase;
      //       })
      //     );
      //   });
    } catch (err) {
      console.error(err);
    }
  };
  const fetchType = async () => {
    let newData = [];
    try {
      const res = await getType();
      console.log(res);
      setType(res);
      // db.collection("types")
      //   .get()
      //   .then((querySnapshot) => {
      //     querySnapshot.forEach((doc) => {
      //       const itemData = doc.data();
      //       newData = itemData.types;
      //     });
      //     console.log(newData, "new data");
      //     setType(_.sortBy(newData));
      //   });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (record) {
      fetchTags();
      fetchBorder();
      fetchType();
      fetchColor();
      // fetchPatches();
      setImage("");
      console.log(record);
      form.setFieldsValue({
        id: record.id,
        issueCode: record.name,
        sortingNumber: record.sortingNumber,
        image: record.image_url,
        description: record.description,
        type: record.type,
        borderType: record.bd_type,
        borderColor: record.bd_color,
        backgroundColor: record.bg_color,
        bsafld_color: record.bsafld_color,
        nameColor: record.name_color,
        yearIssued: record.year,
        quantity: record.quantity,
        tags: record.tags,
      });
    }
  }, [record]);

  return (
    <ModalForm
      isModalVisible={showEditPatch}
      setIsModalVisible={setShowEditPatch}
    >
      <Form
        name="edit-form"
        layout="horizontal"
        form={form}
        onFinish={handleEditProduct}
      >
        <Form.Item
          name="sortingNumber"
          rules={[{ required: true }]}
          label={`Sort Number`}
          hasFeedback
          {...formItemLayout}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="issueCode"
          rules={[{ required: true }]}
          label={`Issued Code`}
          hasFeedback
          {...formItemLayout}
        >
          <Input />
        </Form.Item>
        <Form.Item
          style={{ display: "none" }}
          name="id"
          rules={[{ required: true }]}
          label={`ID`}
          hasFeedback
          {...formItemLayout}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="image"
          rules={[{ required: false }]}
          label={`Image`}
          hasFeedback
          {...formItemLayout}
        >
          <Upload {...props}>
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
          {/* <input type="file" onChange={handleChange} multiple />
          {showProgress ? <Progress percent={progress} showInfo={false} /> : ""} */}
        </Form.Item>
        <Form.Item
          name="description"
          rules={[{ required: true }]}
          label={`Description`}
          hasFeedback
          {...formItemLayout}
        >
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item
          name="type"
          rules={[{ required: true }]}
          label={`Type`}
          hasFeedback
          {...formItemLayout}
        >
          <Select
            showSearch
            placeholder=""
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {type.map((item, i) => (
              <Option value={item.id}>
                {item.Abbreviation + "-" + item.Description}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="borderColor"
          rules={[{ required: true }]}
          label={`Border Color`}
          hasFeedback
          {...formItemLayout}
        >
          <Select
            showSearch
            placeholder=""
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.value.toLowerCase().includes(input.toLowerCase())
            }
          >
            {color.map((item, i) => (
              <Option value={item.id}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  {" "}
                  {item.name} - {item.description}
                  <p
                    style={{
                      backgroundColor: `${item.hex}`,
                      width: "15px",
                      height: "15px",
                      display: "inline-block",
                      margin: "0",
                      marginRight: "0.5em",
                    }}
                  ></p>
                </div>
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="borderType"
          rules={[{ required: true }]}
          label={`Border Type`}
          hasFeedback
          {...formItemLayout}
        >
          <Select
            showSearch
            placeholder=""
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {border.map((item) => (
              <Option value={item.id}>
                {item.Abbreviation} - {item.Description}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="backgroundColor"
          rules={[{ required: true }]}
          label={`Background Color`}
          hasFeedback
          {...formItemLayout}
        >
          <Select
            showSearch
            placeholder=""
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.value.toLowerCase().includes(input.toLowerCase())
            }
          >
            {color.map((item, i) => (
              <Option value={item.id}>
                {" "}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  {" "}
                  {item.name} - {item.description}
                  <p
                    style={{
                      backgroundColor: `${item.hex}`,
                      width: "15px",
                      height: "15px",
                      display: "inline-block",
                      margin: "0",
                      marginRight: "0.5em",
                    }}
                  ></p>
                </div>
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="nameColor"
          rules={[{ required: true }]}
          label={`Name Color`}
          hasFeedback
          {...formItemLayout}
        >
          <Select
            showSearch
            placeholder=""
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.value.toLowerCase().includes(input.toLowerCase())
            }
          >
            {color.map((item, i) => (
              <Option value={item.id}>
                {" "}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  {" "}
                  {item.name} - {item.description}
                  <p
                    style={{
                      backgroundColor: `#${item.hex}`,
                      width: "15px",
                      height: "15px",
                      display: "inline-block",
                      margin: "0",
                      marginRight: "0.5em",
                    }}
                  ></p>
                </div>
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="bsafld_color"
          rules={[{ required: true }]}
          label={`BSA/FDL`}
          hasFeedback
          {...formItemLayout}
        >
          <Select
            showSearch
            placeholder=""
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.value.toLowerCase().includes(input.toLowerCase())
            }
          >
            {color.map((item, i) => (
              <Option value={item.id}>
                {" "}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  {" "}
                  {item.name} - {item.description}
                  <p
                    style={{
                      backgroundColor: `#${item.hex}`,
                      width: "15px",
                      height: "15px",
                      display: "inline-block",
                      margin: "0",
                      marginRight: "0.5em",
                    }}
                  ></p>
                </div>
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="yearIssued"
          rules={[{ required: false }]}
          label={`Year Issued`}
          hasFeedback
          {...formItemLayout}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="quantity"
          rules={[{ required: false }]}
          label={`Quantity`}
          hasFeedback
          {...formItemLayout}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="tags"
          rules={[{ required: false }]}
          label={`Tags`}
          hasFeedback
          {...formItemLayout}
        >
          <Select
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder="Please select"
          >
            {tags.map((item, i) => (
              <Option key={i} value={item.id}>
                {item.title} - {item.description}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Row justify="center">
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            style={{ width: "80%" }}
          >
            Submit
          </Button>
        </Row>
      </Form>
    </ModalForm>
  );
}
