import React, { useState, useEffect } from "react";
import {
  Table,
  Modal,
  Avatar,
  Button,
  Row,
  Col,
  DatePicker,
  Form,
  Input,
  Select,
  Upload,
  message,
  Progress,
  Popover,
  Image,
  Tag,
  Badge,
} from "antd";
import { UploadOutlined, MessageOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import AddPatchModal from "./AddPatchModal";
import EditPatchModal from "./EditPatchModal";
import DropOption from "../components/DropOption";
import ModalForm from "../components/ModalForm";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import {
  getGuide,
  createGuide,
  updateGuide,
  deleteGuide,
} from "../utils/guideApi";
import { getType } from "../utils/typesApi";
import { getColor } from "../utils/colorApi";
import { getBorder } from "../utils/borderApi";
import { getTag } from "../utils/tagsApi";
import { getPatch, deletePatch } from "../utils/patchesApi";

import { auth, db, storage } from "../firebase";
const { confirm } = Modal;
const { Search } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;

const Patches = (props) => {
  const [showEditPatch, setShowEditPatch] = useState(false);
  const [showPatchAdd, setShowPatchAdd] = useState(false);
  const [uploadImg, setUploadImg] = useState();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [tag, setTag] = useState([]);
  const [color, setColor] = useState([]);
  const [border, setBorder] = useState([]);
  const [progress, setProgress] = useState();
  const [showProgress, setShowProgress] = useState(false);
  const [image, setImage] = useState();
  const [record, setRecord] = useState();
  const [type, setType] = useState([]);

  const columns = [
    {
      title: "S.No",
      dataIndex: "sortingNumber",
      key: "sortingNumber",
      // width: "5%",
      align: "center",
      sorter: (a, b) => a.sortingNumber - b.sortingNumber,
    },
    {
      title: "Issue Code",
      dataIndex: "name",
      key: "name",
      align: "center",
      // width: "5%",
      sorter: {
        compare: (a, b) => a.name.length - b.name.length,
        multiple: 7,
      },
    },
    {
      title: "Image",
      dataIndex: "image_url",
      key: "image_url",
      width: 150,
      render: (text) => (
        <Avatar.Group
          maxCount={1}
          size={64}
          maxStyle={{
            color: "#f56a00",
            backgroundColor: "#fde3cf",
          }}
        >
          {text.map((item) => (
            <Avatar size={64} src={<Image src={item} />} />
          ))}
        </Avatar.Group>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 300,
      render: (text) => (
        <>
          <p className="hideText ">{text} </p>
          {text.length >= 40 ? (
            <Popover
              content={text}
              trigger="click"
              placement="rightTop"
              arrowPointAtCenter
            >
              <a href="#">more</a>
            </Popover>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      width: 150,

      render: (text) =>
        text?.map((item) => (
          <Tag color="magenta" style={{ marginBottom: "0.5em" }}>
            {tag.find((t) => t.id === item).title}
          </Tag>
        )),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      align: "center",
      // width: "5%",
      sorter: {
        compare: (a, b) => a.type.length - b.type.length,
        multiple: 6,
      },
      render: (text) => (
        <span>{type.find((t) => t.id === text).Abbreviation}</span>
      ),
    },
    {
      title: "Border Color",
      dataIndex: "bd_color",
      key: "bd_color",
      align: "center",
      // width: "5%",
      sorter: {
        compare: (a, b) => a.bd_color.length - b.bd_color.length,
        multiple: 5,
      },
      render: (text) => (
        <span>
          <p
            style={{
              backgroundColor: `${color.find((c) => c.id === text).hex}`,
              width: "20px",
              height: "20px",
              display: "inline-block",
              margin: "0",
              marginRight: "0.5em",
            }}
          ></p>
          {color.find((c) => c.id === text).description}
        </span>
      ),
    },
    {
      title: "Border Type",
      dataIndex: "bd_type",
      key: "bd_type",
      align: "center",
      // width: 100,
      sorter: {
        compare: (a, b) => a.bd_type.length - b.bd_type.length,
        multiple: 4,
      },
      render: (text) => (
        <span>{border.find((b) => b.id === text).Abbreviation}</span>
      ),
    },
    {
      title: "BKGD Color",
      dataIndex: "bg_color",
      key: "bg_color",
      align: "center",
      // width: "5%",
      sorter: {
        compare: (a, b) => a.bg_color.length - b.bg_color.length,
        multiple: 3,
      },
      render: (text) => {
        // console.log(text.split("|")[2].replace("0xFF", ""));
        return (
          <>
            <p
              style={{
                backgroundColor: `${color.find((c) => c.id === text).hex}`,
                width: "20px",
                height: "20px",
                display: "inline-block",
                margin: "0",
                marginRight: "0.5em",
              }}
            ></p>

            <span>{color.find((c) => c.id === text).description}</span>
          </>
        );
      },
    },
    {
      title: "Name Color",
      dataIndex: "name_color",
      key: "name_color",
      align: "center",
      // width: "5%",
      sorter: {
        compare: (a, b) => a.name_color.length - b.name_color.length,
        multiple: 2,
      },
      render: (text) => (
        <span>
          {" "}
          <p
            style={{
              backgroundColor: `${color.find((c) => c.id === text).hex}`,
              width: "20px",
              height: "20px",
              display: "inline-block",
              margin: "0",
              marginRight: "0.5em",
            }}
          ></p>
          {color.find((c) => c.id === text).description}
        </span>
      ),
    },
    {
      title: "BSA/FDL",
      dataIndex: "bsafld_color",
      key: "bsafld_color",
      align: "center",
      // width: "7%",
      sorter: {
        compare: (a, b) => a.bsafld_color.length - b.bsafld_color.length,
        multiple: 1,
      },
      render: (text) => (
        <span>
          {" "}
          <p
            style={{
              backgroundColor: `${color.find((c) => c.id === text).hex}`,
              width: "20px",
              height: "20px",
              display: "inline-block",
              margin: "0",
              marginRight: "0.5em",
            }}
          ></p>
          {color.find((c) => c.id === text).description}
        </span>
      ),
    },
    {
      title: "Year",
      dataIndex: "year",
      key: "year",
      align: "center",
      // width: "5%",
      sorter: {
        compare: (a, b) => a.year - b.year,
        multiple: 8,
      },
    },
    {
      title: "QTY",
      dataIndex: "quantity",
      key: "quantity",
      width: "5%",
      align: "center",
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      // width: "5%",
      render: (text, record) => {
        return (
          <DropOption
            onMenuClick={(e) => handleMenuClick(record, e)}
            menuOptions={[
              { key: "1", name: `Update` },
              { key: "2", name: `Delete` },
              { key: "3", name: `Duplicate` },
            ]}
          />
        );
      },
    },
  ];

  const [form] = Form.useForm();
  const { TextArea } = Input;

  const handleChange = (event) => {
    const file = Array.from(event.target.files);
    setImage({ file });
  };

  const handleMenuClick = (record, e) => {
    if (e.key === "1") {
      setShowProgress(false);
      setImage(null);
      setProgress(0);
      setRecord(record);
      setShowEditPatch(true);
    } else if (e.key === "2") {
      confirm({
        title: `Are you sure you want to delete this item?`,
        async onOk() {
          const res = await deletePatch(record.id);
          fetchPatches();
          message.success(res);
        },
      });
    } else if (e.key === "3") {
      setShowProgress(false);
      setImage(null);
      setProgress(0);
      setRecord(record);
      setShowPatchAdd(true);
    }
  };
  const fetchPatches = async () => {
    let newData = [];
    try {
      const res = await getPatch(props.location.state);
      setData(res);
      // db.collection("tags")
      //   .get()
      //   .then((querySnapshot) => {
      //     querySnapshot.forEach((doc) => {
      //       const itemData = doc.data();
      //       newData.push({
      //         id: doc.id,
      //         tag: itemData.tag,
      //       });
      //     });
      //     console.log(newData, "new data");
      //     setTags(newData);
      //   });
    } catch (err) {
      console.error(err);
    }
  };

  // const fetchGuides = async () => {
  //   let newData = [];
  //   try {
  //     db.collection("guides")
  //       .get()
  //       .then((querySnapshot) => {
  //         querySnapshot.forEach((doc) => {
  //           const itemData = doc.data();
  //           if (itemData.patches && props.location.state === doc.id) {
  //             console.log(itemData);
  //             setData(itemData.patches);
  //           }
  //         });
  //         console.log(newData, "new data");
  //       });
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  const fetchType = async () => {
    let newData = [];
    try {
      let res = await getType();
      setType(res);
      // db.collection("types")
      //   .get()
      //   .then((querySnapshot) => {
      //     querySnapshot.forEach((doc) => {
      //       const itemData = doc.data();
      //       newData = itemData.types;
      //     });
      //     console.log(newData, "new data");
      //     setType(_.sortBy(newData));
      //   });
    } catch (err) {
      console.error(err);
    }
  };
  const fetchBorder = async () => {
    let newData = [];
    try {
      let res = await getBorder();
      setBorder(res);
    } catch (err) {
      console.error(err);
    }
  };
  const fetchColor = async () => {
    let newData = [];
    try {
      let res = await getColor();
      setColor(res);
    } catch (err) {
      console.error(err);
    }
  };
  const fetchTag = async () => {
    let newData = [];
    try {
      let res = await getTag();
      setTag(res);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    // fetchGuides();
    fetchPatches();
    fetchType();
    fetchTag();
    fetchColor();
    fetchBorder();
  }, []);

  const handleSearch = (keyword) => {
    console.log(keyword);
    if (keyword.length) {
      const newData = data.filter((item) => {
        if (
          item.name.toLowerCase().includes(keyword.toLowerCase()) ||
          item.description.toLowerCase().includes(keyword.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });
      console.log(newData);
      setData(newData);
    } else {
      fetchPatches();
    }
  };
  const handleSearchType = (keyword) => {
    console.log(keyword);
    if (keyword) {
      const newData = data.filter((item) => {
        if (item.type.toLowerCase().includes(keyword.toLowerCase())) {
          return true;
        } else {
          return false;
        }
      });
      console.log(newData);
      setData(newData);
    } else {
      fetchPatches();
    }
  };

  return (
    <div className="App">
      <EditPatchModal
        record={record}
        fetchPatches={fetchPatches}
        showEditPatch={showEditPatch}
        setShowEditPatch={(item) => setShowEditPatch(item)}
        data={data}
        guideId={props.location.state}
      />
      <AddPatchModal
        record={record}
        fetchPatches={fetchPatches}
        showPatchAdd={showPatchAdd}
        setShowPatchAdd={(item) => setShowPatchAdd(item)}
        data={data}
        guideId={props.location.state}
      />

      <Form name="control-ref">
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item name="name">
              <Search placeholder={`Search Name`} onSearch={handleSearch} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="type">
              <Select
                allowClear
                showSearch
                placeholder="Search Type"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={handleSearchType}
              >
                {type.map((item, i) => (
                  <Option value={item.id}>
                    {item.Abbreviation + "-" + item.Description}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={2} offset={10}>
            <Button
              type="ghost"
              onClick={() => {
                setShowPatchAdd(true);
              }}
            >
              Create
            </Button>
          </Col>
        </Row>
      </Form>
      <div
        style={{
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Table
          // {...tableProps}
          pagination={{ position: ["topRight"] }}
          dataSource={data}
          // className={styles.table}
          bordered
          columns={columns}
          simple
          rowKey={(record) => record.id}
          scroll={{ y: 550 }}
        />
      </div>
    </div>
  );
};

export default Patches;
